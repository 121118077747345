import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/falseceilingsidebar"
import Select from 'react-select';
import { Container, Row, Col } from "react-bootstrap"


import FalseCeilingAdvanceCalculator from "./falseceilingComplexcalculator"
import FCAdvanceWithTrayVertical from "./falseCeilingAdvanceWithTrayVertical"
import FCAdvancewithWiringSeparate from "./falseCeilingAdvanceWithWiringSeparate"

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 2
      }}
  />
);
const ColoredYelloLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);
const FalseCeilingCalculator = () => (
  <Layout>
   <SEO
        title="FALSE CEILING CALCULATOR"
        keywords={[
          "tags",
          "Pramri Interiors tages",
          "false ceiling",
          "Tips for false ceiling",
          "Interior Design",
          "Interior Design planning",
          "Gypsum vs POP",
          "Different types of False Ceiling",
          "Modular Kitchen",
          "Kitchen Design",
          "House Painting",
          "Kitchen Laminates",
          "PVC Laminates",
          "Laminates Selection",
          "Small house Design",
          "Small master bedroom design",
          "Master Bedroom design",
          "Woodwork",
          "Home Interiors",
          "tags",
          "Pramri Interiors tags",
          "false ceiling in hindi",
          "Tips for false ceiling in hindi",
          "Interior Design in hindi",
          "Interior Design planning in hindi",
          "Gypsum vs POP in hindi ",
          "Different types of False Ceiling in hindi",
          "Modular Kitchen in hindi",
          "Kitchen Design in hindi ",
          "House Painting in hindi",
          "Kitchen Laminates in hindi",
          "PVC Laminates in hindi",
          "Laminates Selection in hindi",
          "Small house Design in hindi",
          "Small master bedroom design in hindi",
          "Master Bedroom design in hindi",
          "Woodwork in hindi",
          "Home Interiors in hindi" 
        ]}
      />
    <Container>
      <h1>False Ceiling</h1>
      <Row>
        <Col md="9">

        <center><h4><u>ADVANCE CALCULATOR</u></h4></center>
        <ColoredLine color="green" />
        <ColoredThinLine color="#9400D3"/>
        <b>क्या आपको पता है फाल्स सीलिंग का Total Area कैसे निकाला जाता है? अगर नहीं पता तो पहले ये <Link to="https://youtu.be/KMDAhgMctn4">वीडियो</Link> देखें</b>
        <ColoredThinLine color="#9400D3"/>
        <h5>Get your complete False Ceiling Estimates</h5>
        <FalseCeilingAdvanceCalculator/>
        <ColoredLine color="green" />
        <ColoredThinLine color="#9400D3"/>
        <b>क्या आपको पता है फाल्स सीलिंग में Trey Vertical क्या होता है ? अगर नहीं पता तो पहले ये <Link to="https://youtu.be/KMDAhgMctn4">वीडियो</Link> देखें</b>
        <ColoredThinLine color="#9400D3"/>
        <h5>Get your Complete False Ceiling Estimates if the <u>Tray Vertical cost is separate</u> </h5>
        <FCAdvanceWithTrayVertical/>
        <ColoredLine color="green" />
        <br></br>
        <h5>Get your Quick False Ceiling Estimates <u>if the Wiring Cost is separate for each Light Points and Tray Vertical is separate</u></h5>
        <FCAdvancewithWiringSeparate/>
        <ColoredLine color="green" />
        <ColoredYelloLine color="#9400D3"/>
        <b>क्या आप मेरा Youtube Channel subscribe करना चाहेंगे ? अगर हाँ तो इस <Link to="https://www.youtube.com/channel/UCkMSooIIGsUGgt86mkPq1mg?sub_confirmation=1">link</Link>  पर क्लिक करें </b>
        <ColoredYelloLine color="#9400D3"/>
        <ColoredLine color="green" />
        


        
       

        </Col>
        <Col md="3">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Sidebar />
          </div>
        </Col>
      </Row>
     
    </Container>
  </Layout>
)

export default FalseCeilingCalculator
